import { useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import { Cities } from "src/Utils/Data";
import Placeholder from "react-bootstrap/Placeholder";
import { styled } from "@mui/material/styles";
import { MuiTelInput } from "mui-tel-input";
import {
  InputSelect,
  ToggleSelect,
  TextInput,
  ImageUpload,
  CustomText,
  MultiSelect,
} from "src/design/index";
import { Divider, Grid } from "@mui/material";
import { ConfigurationContext } from "src/Utils/Context/ConfigurationContext";
import { useTranslation } from "react-i18next";
import { CustomLoadingButton, SnackBar } from "src/design/index";

const Group = styled(Form.Group)(({ theme }) => ({
  ...theme.typography.mainContent,
  transition: theme.transitions.create("all", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("md")]: {
    width: `calc(50%)`,
  },
  [theme.breakpoints.down("md")]: {
    width: `calc(70%)`,
    padding: "16px",
  },
  [theme.breakpoints.down("sm")]: {
    width: `calc(100%)`,
  },
}));

function RestauInfo({ restaurant_id, Info, id, session_token, admin }) {
  const { constants } = useContext(ConfigurationContext);
  const [canSaveImage, setCanSaveImage] = useState(false);
  const [canSaveInfo, setCanSaveInfo] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const { t } = useTranslation();
  const [loading, setloading] = useState(false);

  const [UploadingCover, setUploadingCover] = useState(false);
  const [newInfo, setNewInfo] = useState({ ...Info });
  const [Cover, setCover] = useState(
    newInfo.Uri
      ? [
          {
            data_url: `https://laddition.ma/Website/restaurant_images/restaurant${restaurant_id}/${restaurant_id}_${newInfo.Uri}.webp`,
          },
        ]
      : []
  );

  const HandleChange = (text, type) => {
    setCanSaveInfo(true);
    setNewInfo((oldInfo) => {
      switch (type) {
        case "NomRestaurant":
          return { ...oldInfo, Nom: text };
        case "Addresse":
          return { ...oldInfo, Addresse: text };
        case "Places":
          return { ...oldInfo, Nb_places: text };
        case "Phone":
          return { ...oldInfo, Phone: text };
        case "IgLink":
          return { ...oldInfo, Insta_link: text };
        case "Booze":
          return { ...oldInfo, Booze: text };
        case "Animation":
          return { ...oldInfo, Animation: text };
        case "Quartier":
          return { ...oldInfo, Quartier: text };
        default:
          return { ...oldInfo, [type]: text };
      }
    });
  };

  const UploadImage = () => {
    if (Cover.length > 0) {
      const formData = new FormData();
      formData.append("image", Cover[0].file);
      formData.append("ownerId", id);
      formData.append("ownerToken", session_token);
      formData.append("restaurant_id", restaurant_id);

      fetch(`https://laddition.ma/Website/UploadCoverToFirebase.php`, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success) {
            setUpdateSuccess(true);
          } else {
            setUpdateError(true);
          }
          setUploadingCover(false);
        })
        .catch((e) => {
          console.log(e);
          setUpdateError(true);
          setUploadingCover(false);
        });
    }
  };

  const Submit = () => {
    setloading(true);
    fetch(`https://laddition.ma/Website/UpdateRestaurant.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: id,
        ownerToken: session_token,
        restaurant_id: restaurant_id,
        name: newInfo.Nom,
        city: newInfo.City,
        quartier: newInfo.Quartier,
        phone: newInfo.Phone,
        address: newInfo.Addresse,
        payment_method: newInfo.Payment_method,
        category: newInfo.Category.join(";"),
        number_tables: newInfo.Nb_places,
        booz: newInfo.Booz,
        animation: newInfo.Animation,
        insta_link: newInfo.Insta_link,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setUpdateSuccess(true);
          setCanSaveInfo(false);
          setloading(false);
        } else {
          setUpdateError(true);
        }
      })
      .catch((e) => {
        setUpdateError(true);
        setloading(false);
      });
  };

  return newInfo.Nom ? (
    <Grid container justifyContent="space-between">
      <Grid item md={5} xs={12}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <CustomText
              label={t("restaurant.restaurantInfoPage.restaurantInfo.header")}
              type="section"
            />
          </Grid>
          <Grid item>
            <TextInput
              disabled={!admin}
              value={newInfo.Nom}
              onChange={(value) => HandleChange(value, "NomRestaurant")}
              label={t(
                "restaurant.restaurantInfoPage.restaurantInfo.ImportantInformation.fields.name"
              )}
              fullwidth
              required
              size="small"
            />
          </Grid>
          <Grid item>
            <TextInput
              value={newInfo.Addresse}
              onChange={(value) => {
                HandleChange(value, "Addresse");
              }}
              label={t(
                "restaurant.restaurantInfoPage.restaurantInfo.ImportantInformation.fields.address"
              )}
              fullwidth
              size="small"
              required
            />
          </Grid>
          <Grid item>
            <TextInput
              value={newInfo.Quartier}
              onChange={(value) => {
                HandleChange(value, "Quartier");
              }}
              label={t(
                "restaurant.restaurantInfoPage.restaurantInfo.ImportantInformation.fields.quarter"
              )}
              fullwidth
              required
              size="small"
            />
          </Grid>
          <Grid item>
            <InputSelect
              disabled={!admin}
              required
              label={t(
                "restaurant.restaurantInfoPage.restaurantInfo.ImportantInformation.fields.city"
              )}
              onChange={(event) => {
                setCanSaveInfo(true);
                setNewInfo((oldInfo) => {
                  return { ...oldInfo, City: event.target.value };
                });
              }}
              value={newInfo.City}
              size="small"
              options={Cities.map((value) => value.city)}
            />
          </Grid>
          <Grid item>
            <MultiSelect
              disabled={!admin}
              size="small"
              value={newInfo.Category}
              label={t("common.tags")}
              onChange={(value) => {
                setCanSaveInfo(true);
                setNewInfo({ ...Info, Category: value });
              }}
              options={constants.tags}
            />
          </Grid>
          <Grid item>
            <TextInput
              value={newInfo.Nb_places}
              onChange={(value) => {
                HandleChange(value, "Places");
              }}
              label={t(
                "restaurant.restaurantInfoPage.restaurantInfo.ImportantInformation.fields.numberOfSeats"
              )}
              fullwidth
              type="number"
              size="small"
              required
            />
          </Grid>
          <Grid item>
            <MuiTelInput
              label={t("common.phone")}
              fullWidth
              required
              value={newInfo.Phone}
              onChange={(value) => {
                HandleChange(value, "Phone");
              }}
              defaultCountry="MA"
              size="small"
            />
          </Grid>
          <Grid item>
            <ToggleSelect
              label={t(
                "restaurant.restaurantInfoPage.restaurantInfo.ImportantInformation.fields.tpe"
              )}
              value={Number(newInfo.Payment_method)}
              onChange={(event, newAlignment) => {
                setCanSaveInfo(true);
                setNewInfo((oldInfo) => {
                  return { ...oldInfo, Payment_method: newAlignment };
                });
              }}
              options={[1, 0]}
              names={["Oui", "Non"]}
            />
          </Grid>
          <Grid item>
            <ToggleSelect
              label={t(
                "restaurant.restaurantInfoPage.restaurantInfo.ImportantInformation.fields.booze"
              )}
              value={newInfo.Booz}
              onChange={(event, newAlignment) => {
                setCanSaveInfo(true);
                setNewInfo((oldInfo) => {
                  return { ...oldInfo, Booz: newAlignment };
                });
              }}
              options={[1, 0]}
              names={["Oui", "Non"]}
            />
          </Grid>
          <Grid item>
            <ToggleSelect
              label={t(
                "restaurant.restaurantInfoPage.restaurantInfo.ImportantInformation.fields.animation"
              )}
              value={newInfo.Animation}
              onChange={(event, newAlignment) => {
                setCanSaveInfo(true);
                setNewInfo((oldInfo) => {
                  return { ...oldInfo, Animation: newAlignment };
                });
              }}
              options={[1, 0]}
              names={["Oui", "Non"]}
            />
          </Grid>
          <Grid item>
            <TextInput
              value={newInfo.Insta_link}
              onChange={(value) => {
                HandleChange(value, "IgLink");
              }}
              label={t(
                "restaurant.restaurantInfoPage.restaurantInfo.ImportantInformation.fields.instagram_link"
              )}
              fullwidth
              size="small"
              required
            />
          </Grid>
          <Grid item>
            <Group
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: 10,
                minHeight: 40,
              }}
            >
              <CustomLoadingButton
                variant="contained"
                color="success"
                disabled={!canSaveInfo}
                loading={loading}
                onSubmit={Submit}
                label={t("common.save")}
              />
            </Group>
          </Grid>
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem sx={{ borderWidth: 1.5 }} />
      <Grid md={6} xs={12} item>
        <Grid container direction="column" spacing={2} alignItems="center">
          <Grid item>
            <CustomText
              label={t(
                "restaurant.restaurantInfoPage.restaurantInfo.cover.header"
              )}
              type="section"
            />
          </Grid>
          <Grid item width="90%">
            <img
              style={{ width: "100%", height: "100%" }}
              src={
                Cover.length > 0
                  ? Cover[0]["data_url"]
                  : require("src/assets/NoImage.png")
              }
              alt="Cover"
            />
          </Grid>
          <Grid item width={"80%"}>
            <ImageUpload
              canSave={canSaveImage}
              image={Cover}
              uploadimg={() => {
                setUploadingCover(true);
                UploadImage();
              }}
              uploading={UploadingCover}
              setImages={(imageList) => {
                setCanSaveImage(true);
                setCover(imageList);
              }}
              uploadLabel={t(
                Cover.length > 0
                  ? "restaurant.restaurantInfoPage.restaurantInfo.cover.replaceCover"
                  : "restaurant.restaurantInfoPage.restaurantInfo.cover.addCover"
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <SnackBar
        onClose={() => setUpdateSuccess(false)}
        open={updateSuccess}
        handleClose={() => setUpdateSuccess(false)}
        message={t(
          "restaurant.restaurantInfoPage.restaurantInfo.ImportantInformation.uploadSuccess"
        )}
      />
      <SnackBar
        onClose={() => setUpdateError(false)}
        open={updateError}
        handleClose={() => setUpdateError(false)}
        message={t(
          "restaurant.restaurantInfoPage.restaurantInfo.ImportantInformation.uploadError"
        )}
      />
    </Grid>
  ) : (
    <>
      <Placeholder animation="glow">
        <Placeholder xs={7} />
      </Placeholder>
      <Placeholder animation="glow">
        <Placeholder xs={6} /> {""}
        <Placeholder xs={3} />
        <Placeholder xs={10} />
        <Placeholder xs={10} />
        <Placeholder xs={10} />
      </Placeholder>
    </>
  );
}

export default RestauInfo;
