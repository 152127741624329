import ReactDOM from "react-dom/client";
import "src/index.css";
import App from "src/App";
import reportWebVitals from "src/reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SignUp from "src/components/Authentication/SignUp/index";
import "react-circular-progressbar/dist/styles.css";
import SignIn from "src/components/Authentication/SignIn";
import { ConfigurationProvider } from "src/Utils/Context/ConfigurationContext";
import { CookiesProvider } from "react-cookie";
import PageRestau from "src/components/Main/Pages/Home/PageRestau";
import { PasswordReset } from "src/components/HomePage/PasswordReset";
import "src/i18n";
import { About } from "src/components/HomePage/Pages/About";
import TermsAndConditions from "src/components/HomePage/Pages/TermsAndConditions";
import EmailVerificationPage from "src/components/HomePage/VerifyEmail";
import Acceuil from "src/components/Main/Pages/Home/Acceuil";
import BookingsPage from "src/components/Main/Pages/Bookings";
import { Deals } from "src/components/Main/Pages/Deals";
import { AddRestaurant } from "src/components/Main/Pages/AddRestaurant";
import HomePage from "src/components/Main/Pages/Home/HomePage";
import UserProvider from "src/Utils/Context/UserContext";
import AdminDashboard from "src/components/Main/Pages/AdminDashboard";
import LinkRestaurant from "src/components/Main/Pages/AdminDashboard/LinkRestaurant";
import AdminBookings from "src/components/Main/Pages/AdminDashboard/Bookings";
import AdminDeals from "src/components/Main/Pages/AdminDashboard/Deals";
import KPIS from "src/components/Main/Pages/AdminDashboard/KPI";
import FeatureSwitches from "src/components/Main/Pages/AdminDashboard/FS";
import AccountRequests from "src/components/Main/Pages/AdminDashboard/AccountRequests";
import UserDashboard from "src/components/Main/Pages/UserDashboard";
import HomeDashboard from "src/components/Main/Pages/HomeDashboard";
import BookSlot from "src/components/Booking";
import MobileAccountDeletion from "./components/HomePage/Pages/MobileAccountDeletion";

const root = ReactDOM.createRoot(document.getElementById("root"));

function Index() {
  return (
    <UserProvider>
      <ConfigurationProvider>
        <CookiesProvider>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={<Navigate to={"/Home/About"} replace={true} />}
              />
              <Route path="Home" element={<App />}>
                <Route path="About" element={<About />} />
                <Route
                  path="Terms&Conditions"
                  element={<TermsAndConditions />}
                />
                <Route
                  path="MobileAccountDeletion"
                  element={<MobileAccountDeletion />}
                />
                <Route path="SignUp" element={<SignUp />} />
                <Route path="booking/:city/:slug" element={<BookSlot />} />
              </Route>
              <Route path="/login" element={<SignIn />} />
              <Route path="/restaurant" element={<PageRestau />}>
                <Route path="home" element={<HomeDashboard />} />
                <Route path="restaurant-list" element={<Acceuil />} />
                <Route path=":restaurantId" element={<HomePage />} />
                <Route path="bookings" element={<BookingsPage />} />
                <Route path="statistics" element={<UserDashboard />} />
                <Route path="add-restaurant" element={<AddRestaurant />} />
                <Route path="deals" element={<Deals />} />
                <Route path="admin-dashboard" element={<AdminDashboard />}>
                  <Route
                    path="restaurant-link/:restaurantId"
                    element={<LinkRestaurant />}
                  />
                  <Route path="restaurant-link" element={<LinkRestaurant />} />
                  <Route
                    path="bookings/:restaurantId"
                    element={<AdminBookings />}
                  />
                  <Route path="bookings" element={<AdminBookings />} />
                  <Route path="deals/:restaurantId" element={<AdminDeals />} />
                  <Route path="deals" element={<AdminDeals />} />
                  <Route path="kpis" element={<KPIS />} />
                  <Route
                    path="feature-switches"
                    element={<FeatureSwitches />}
                  />
                  <Route
                    path="account-requests"
                    element={<AccountRequests />}
                  />
                </Route>
                <Route
                  path="*"
                  element={<Navigate to="/restaurant/home" replace />}
                />
              </Route>
              <Route path="/reset-password" element={<PasswordReset />} />
              <Route
                path="/verify-email/:token"
                element={<EmailVerificationPage />}
              />
              <Route path="*" element={<Navigate to="/Home/About" replace />} />
            </Routes>
          </BrowserRouter>
        </CookiesProvider>
      </ConfigurationProvider>
    </UserProvider>
  );
}

root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
