import { Grid } from "@mui/material";
import ClickOnAccountImage from "src/assets/accountDeletionInstructions/goToAccount.png";
import ConfirmRequestImage from "src/assets/accountDeletionInstructions/confirmDelete.png";
import ClickOnDeleteImage from "src/assets/accountDeletionInstructions/clickDelete.png";
import ClickOnSettingsImage from "src/assets/accountDeletionInstructions/goToSettings.png";

const MobileAccountDeletion = () => (
  <Grid item>
    <h1>Account Deletion for Laddition mobile app</h1>
    <p>To delete your account, please follow the instructions below :</p>

    <p>1. Click on the "Account" icon in the top right corner of the screen.</p>

    <p>2. Click on the "Settings" option.</p>

    <p>3. Click on the "Delete Account" option.</p>

    <p>4. Confirm you request</p>

    <Grid
      xs={12}
      container
      marginBottom={10}
      rowGap={10}
      justifyContent={"space-between"}
    >
      <Grid item xs={10} md={6} lg={3}>
        <img src={ClickOnAccountImage} alt="delete-account" width="100%" />
      </Grid>
      <Grid item xs={10} md={6} lg={3}>
        <img src={ClickOnSettingsImage} alt="delete-account" width="100%" />
      </Grid>
      <Grid item xs={10} md={6} lg={3}>
        <img src={ClickOnDeleteImage} alt="delete-account" width="100%" />
      </Grid>
      <Grid item xs={10} md={6} lg={3}>
        <img src={ConfirmRequestImage} alt="delete-account" width="100%" />
      </Grid>
    </Grid>

    <p>
      Once you have completed these steps, your account will be deleted
      permanently and your data will be removed from our servers after 30 days.
    </p>
  </Grid>
);

export default MobileAccountDeletion;
